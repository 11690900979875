import React, { Component } from "react";

class NavBar extends Component {
  state = { page: "mynest" };

  signout = () => {
    const { setCookie, sessionId, api, onSignOut } = this.props

    api.signout(sessionId).then(
      function (data) {

        setCookie("");
        onSignOut("");

      })

  }

  render() {
    const { admin, streetLightView, lcdBoardView, spatialView, reportsView } = this.props
    return (
      <nav className="navbar navbar-dark navbar-expand-md fixed-top" >
        <div className="container-fluid">
          <a className="navbar-brand" id="brand" href="/">
            <div >
              <img id="Logo" src="/img/logo.png" width="45" height="45" alt="logo" />
              <img src="/img/title-white.png" width="106" height="21" alt="logo" />
            </div>
          </a>

          <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1" >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse flex-grow-0 justify-content-end" id="navcol-1">
            <ul className="nav navbar-nav">

              {this.renderTab("Semáforos", "streetLight", "sunny", null, streetLightView === true)}
              {this.renderTab("Tableros LCD", "lcdBoards", "bus", null, lcdBoardView === true)}
              {this.renderTab("Mis dispositivos", "devices", "outlet", null, ((!streetLightView && !lcdBoardView && !spatialView) || admin))}
              {this.renderTab("Mi sala", "spatial", "person", null, (spatialView || admin))}
              {this.renderTab("Admin", "admin", "cog", null, admin)}
              {this.renderTab("Licencias", "licences", "paper", null, admin)}
              {this.renderTab("Estado", "reports", "paper", null, reportsView || admin)}
              {this.renderTab("Reportes", "massReports", "paper", null, reportsView || admin)}
              {this.renderTab("Productividad", "productivity", "paper", null, reportsView || admin)}
              {this.renderTab("Cerrar sesión", "signout", "log-out", this.signout)}


            </ul>
          </div>
        </div>
      </nav>
    );
  }

  renderTab(label, id, icon, onClick, condition = true) {

    if (condition) {
      return <li className="nav-item" role="presentation"><div className={this.getTabClass(id)} onClick={onClick ? onClick : () => this.props.onTabClicked(id)}>
        <ion-icon name={icon}></ion-icon>&nbsp;{label}</div></li>
    }
    else return null;

  }

  getTabClass(tab) {
    const { page } = this.props;
    return page === tab ? "nav-link active" : "nav-link";
  }

}

export default NavBar;
