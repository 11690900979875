import React, { Component } from "react";
class User extends Component {
  state = { extended: false };

  constructor(props) {
    super();
    const { user, devices, groups } = props;
    this.state = {
      extended: false,
      name: user.name,
      username: user.username,
      email: user.email,
      emailVerified: user.emailVerified,
      lastLogin: user.lastLogin == null ? "--/--/--" : user.lastLogin,
      devices: devices,
      groups: groups,
      _id: user._id,
      insertionTimestamp: user.insertionTimestamp,
      language: user.language,
      alexaEnabled: user.alexaEnabled,
      googleHomeEnabled: user.googleHomeEnabled,
      siriEnabled: user.siriEnabled,
      IFTTTEnabled: user.IFTTTEnabled,
      ips: user.ips,
      ipInfo: user.ipInfo == null ? { country_code: "--" } : user.ipInfo,
      timezone: user.timezone,
      planType: (user.plan != null ? user.plan.type : "Basic"),
      validUntil: (user.plan != null ? user.plan.validUntil : ""),
      planTypes: ["Basic", "Basic Plus", "Pro", "Business"],
      languages: ["EN", "ES", "DE", "CZ", "FR"],
      timeZones: ["GMT-11", "GMT-10", "GMT-09", "GMT-08", "GMT-07", "GMT-06", "GMT-05", "GMT-04", "GMT-03", "GMT-02", "GMT-01",
        "GMT+00", "GMT+01", "GMT+02", "GMT+03", "GMT+04", "GMT+05", "GMT+06", "GMT+07", "GMT+08", "GMT+09", "GMT+10", "GMT+11", "GMT+12"]
    };
  }

  viewClicked = () => {
    const { admin } = this.props;
    const { extended } = this.state;
    console.log("View Clicked", this, admin);
    if (admin === "Admin") {
      if (extended) this.setState({ extended: false });
      else this.setState({ extended: true });
    }

  };

  updateInfoClicked = (email, name, username, planType, validUntil, language, timezone) => {
    const { onEvent } = this.props;
    console.log("Update Info Clicked", this);
    onEvent({
      name: "updateInfo", user: {
        email: email,
        name: name,
        username: username,
        plan: { type: planType, validUntil: validUntil },
        language: language,
        timezone: timezone
      }
    })
    this.setState({ extended: false });

  };

  nameChanged = (value) => {
    this.setState({ name: value })
  }

  emailChanged = (value) => {
    this.setState({ email: value })
  }

  languageChanged = (value) => {
    this.setState({ language: value })
  }

  timezoneChanged = (value) => {
    this.setState({ timezone: value })
  }

  planTypeChanged = (value) => {
    this.setState({ planType: value })
  }

  validUntilChanged = (value) => {
    this.setState({ validUntil: value })
  }

  render() {
    const { admin, devices, groups, user } = this.props;
    const { extended } = this.state;
    let ipInfo = user.ipInfo == null ? { country_code: "--" } : user.ipInfo
    var fun = this.viewClicked;
    if (extended) fun = null;

    if (admin) {
      return <div className="admin-card" onClick={fun}>
        <div className="row">
          <div className="col-xsm-12 col-md-6">
            {this.renderFlag(ipInfo.country_code)}
            {this.renderPlanIcon()}
            <h6 className="table-field main-label">{user.username}</h6>

            <div className="table-field">
              <div className="icon">
                <ion-icon name="outlet"></ion-icon>
                <h6>{devices}</h6>
              </div>
              <div className="icon">
                <ion-icon name="grid" ></ion-icon>
                <h6>{groups}</h6>
              </div>
            </div>

          </div>
          <div className="col-xsm-12 col-md-6">

            <h6 className="table-field">@:</h6>
            <h6 className="table-field main-label">{user.email}</h6>
            <h6 className="table-field">{user.lastLogin.substring(0, 10)}</h6>
          </div>

        </div>
        {this.renderExtraInfoLight()}
      </div>
    } else return null;

  }

  renderAlexaCheck() {

    const { alexaEnabled } = this.props.user;
    if (alexaEnabled) {
      return <div className="d-flex integration-table-field"><ion-icon name="logo-angular"></ion-icon><ion-icon name="checkmark"></ion-icon></div>
    }
    else return <div className="d-flex integration-table-field"><ion-icon name="logo-angular"></ion-icon><ion-icon name="close"></ion-icon></div>

  }

  renderSiriCheck() {
    const { siriEnabled } = this.props.user;
    if (siriEnabled) {
      return <div className="d-flex integration-table-field"><ion-icon name="logo-apple"></ion-icon><ion-icon name="checkmark"></ion-icon></div>
    }
    else return <div className="d-flex integration-table-field"><ion-icon name="logo-apple"></ion-icon><ion-icon name="close"></ion-icon></div>

  }

  renderGoogleCheck() {
    const { googleHomeEnabled } = this.props.user;
    if (googleHomeEnabled) {
      return <div className="d-flex integration-table-field"><ion-icon name="logo-google"></ion-icon><ion-icon name="checkmark"></ion-icon></div>
    }
    else return <div className="d-flex integration-table-field"><ion-icon name="logo-google"></ion-icon><ion-icon name="close"></ion-icon></div>


  }

  renderIFTTTCheck() {
    const { IFTTTEnabled } = this.props.user;
    if (IFTTTEnabled) {
      return <div className="d-flex integration-table-field"><ion-icon name="information"></ion-icon><ion-icon name="checkmark"></ion-icon></div>
    }
    else return <div className="d-flex integration-table-field"><ion-icon name="information"></ion-icon><ion-icon name="close"></ion-icon></div>


  }

  renderFlag(country_code) {
    var size = 18;
    if (country_code === "ES") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_spain_480px.png" alt="Spain" height={size} width={size} />
      </div>
    } else if (country_code === "US") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_usa_480px.png" alt="USA" height={size} width={size} />
      </div>
    } else if (country_code === "CZ") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_czech_republic_480px_3.png" alt="Czech Republic" height={size} width={size} />
      </div>
    } else if (country_code === "MX") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_mexico_480px.png" alt="Mexico" height={size} width={size} />
      </div>
    } else if (country_code === "DE") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_germany_480px_2.png" alt="CGermany" height={size} width={size} />
      </div>
    } else if (country_code === "IN") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_india_480px.png" alt="India" height={size} width={size} />
      </div>
    } else if (country_code === "GB") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_great_britain_480px.png" alt="GreatBritain" height={size} width={size} />
      </div>
    } else if (country_code === "PT") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_portugal_480px_1.png" alt="Portugal" height={size} width={size} />
      </div>
    } else if (country_code === "BR") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_brazil_480px.png" alt="Brasil" height={size} width={size} />
      </div>
    } else if (country_code === "CL") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_chile_480px.png" alt="Chile" height={size} width={size} />
      </div>
    } else if (country_code === "CA") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_canada_480px.png" alt="Canada" height={size} width={size} />
      </div>
    } else if (country_code === "CH") {
      return <div className="country-flag">
        <img src="/img/flags/icons8_china_480px.png" alt="China" height={size} width={size} />
      </div>
    } else {
      return <div className="country-flag">
        <img src="/img/flags/icons8_flag_pole_filled_500px.png" alt="Unknown Country" height={size} width={size} />
      </div>
    }

  }

  renderPlanIcon() {
    const { user } = this.props
    var classnames = "icon";
    if (user.plan.type === "Basic Plus") {
      classnames += " plan-basicplus";
    } else if (user.plan.type === "Pro") {
      classnames += " plan-pro";
    } else if (user.plan.type === "Business") {
      classnames += " plan-business";
    }
    return <div className={classnames}>
      <ion-icon name="person"></ion-icon>
    </div>
  }

  renderExtraInfoLight() {
    const { onEvent } = this.props;
    const { email, extended, username } = this.state;
    if (!extended) {
      return null

    } else {
      return (

        <div className="btn-group d-flex justify-content-center" role="group">
          <button className="mybtn" type="button" onClick={() => onEvent({ name: "deleteUser", user: { email: email } })}>Eliminar</button>
          <button className="mybtn" type="button" onClick={() => onEvent({ name: "turnIntoAdmin", user: { email: email } })}>Hacer Admin</button>
          <button className="mybtn" type="button" onClick={() => onEvent({ name: "streetLightView", username: username })}>Vista Semáforos</button>
          <button className="mybtn" type="button" onClick={() => onEvent({ name: "spatialView", username: username })}>Vista Spatial</button>
          <button className="mybtn" type="button" onClick={() => onEvent({ name: "reportsView", username: username })}>Vista Reportes</button>
          <button className="mybtn" type="button" onClick={this.viewClicked}>Cerrar</button>
        </div>

      )
    }
  }

  renderExtraInfo() {
    const { onEvent } = this.props;
    const { ipInfo,
      insertionTimestamp,
      name,
      username,
      email,
      emailVerified,
      _id,
      extended,
      planTypes,
      timeZones,
      languages,
      planType,
      validUntil,
      language,
      ips,
      timezone } = this.state;
    if (!extended) {
      return null

    } else if (extended) {
      var created = new Date();
      if (insertionTimestamp != null) created = new Date(insertionTimestamp * 1000);
      return <div className="admin-card-open">
        <div className="user-extended-controls">
          <div>
            <div className="user-info">
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">Name:</h6>
                  <input type="text" className="insight-value" value={name} onChange={event => this.nameChanged(event.target.value)} />
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">e-mail:</h6>
                  <input type="text" className="insight-value" value={email} onChange={event => this.emailChanged(event.target.value)} />
                </div>
              </div>
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">Plan Type:</h6>
                  <select
                    className="plan-type-select"
                    value={planType}
                    onChange={event => this.planTypeChanged(event.target.value)}
                  >
                    {planTypes.map(tp => (
                      <option key={tp} value={tp}>
                        {tp}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">Valid Until:</h6>
                  <input
                    type="date" name="validuntil" className="plan-valid-until" value={validUntil}
                    onChange={event => this.validUntilChanged(event.target.value)}
                  />

                </div>
              </div>
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">Language:</h6>
                  <select
                    className="plan-type-select"
                    value={language}
                    onChange={event => this.languageChanged(event.target.value)}
                  >
                    {languages.map(ln => (
                      <option key={ln} value={ln}>
                        {ln}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">Time Zone:</h6>
                  <select
                    className="plan-type-select"
                    value={timezone}
                    onChange={event => this.timezoneChanged(event.target.value)}
                  >
                    {timeZones.map(tz => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">E-mail verified:</h6>
                  <h6 className="insight-value">{emailVerified ? "YES" : "NO"} </h6>
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">id:</h6>
                  <h6 className="insight-value">{_id} </h6>
                </div>
              </div>
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">Created:</h6>
                  <h6 className="insight-value">{created.getDate() + "/" + created.getMonth() + "/" + created.getFullYear()} </h6>
                </div>
              </div>
              <div className="d-flex flex-wrap double-field">
                <div className="d-flex single-field">
                  <h6 className="field-title">Country:</h6>
                  <h6 className="insight-value">{ipInfo.country_code} </h6>
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">City:</h6>
                  <h6 className="insight-value">{ipInfo.city}</h6>
                </div>
                <div className="d-flex single-field">
                  <h6 className="field-title">ip:</h6>
                  <h6 className="insight-value">{ips.toString()}</h6>
                </div>
              </div>
            </div>
            <div className="btn-group d-flex flex-wrap" role="group">
              <button className="btn btn-primary mybtn" type="button" onClick={() => onEvent({ name: "resetPassword", user: { email: email } })}>Reset password</button>
              <button className="btn btn-primary mybtn" type="button" onClick={() => this.updateInfoClicked(email, name, username, planType, validUntil, language, timezone)}>Update info</button>
              <button className="btn btn-primary mybtn" type="button" onClick={() => onEvent({ name: "googleRequestOk", user: { email: email } })}>Google request ok</button>
              <button className="btn btn-primary mybtn" type="button" onClick={() => onEvent({ name: "deleteUser", user: { email: email } })}>Delete</button>
              <button className="btn btn-primary mybtn" type="button" onClick={this.viewClicked}>Close</button></div>
          </div>
        </div>
      </div>

    }
    else
      return <div>Error</div>;

  }
}

export default User;
