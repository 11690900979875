import React, { Component } from 'react';
import '../../css/lampDetails.css'
var api = require("../../../utils/api-v2");


class LampDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, name: props.device.name, locationUrl: props.device.locationUrl, groupId: props.device.groupId }
	}
	render() {
		const { device } = this.props
		const { editing } = this.state

		return (
			<div className='detail-comtainer'>
				<div className="device-name-title">{device.name}</div>

				{editing ? this.renderEdit() : this.renderNormal()}

			</div>
		);
	}

	renderNormal() {
		const { device } = this.props

		let greenButtonClicked = () => {
			if(device.value === 2){
				api.controlDevice(device._id, "value", "0")
			} else {
				api.controlDevice(device._id, "value", "2")
			}
		}
		let redButtonClicked = () => {
			if(device.value === 1){
				api.controlDevice(device._id, "value", "0")
			} else {
				api.controlDevice(device._id, "value", "1")
			}
		}

		return (
			<div>
				<div className="d-flex justify-content-center my-3">
					<div>{device.connected ? "En linea" : "Offline"}</div>
				</div>
				<div className='prop-wrapper'>
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src="/img/device-features/sprinkler.svg" alt="status" height="20" width="20" /></div>
							<div>Estado:</div>
						</div>
						<div>{device.powerState === "ON" ? "Encendida" : "Apagada"}</div>
					</div>
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src="/img/device-features/gauge.svg" alt="status" height="20" width="20" /></div>
							<div>Batería:</div>
						</div>
						<div>{device.battery ? (device.battery + " Volts") : "0 Volts"}</div>
					</div>
				</div>

				<div className="d-flex justify-content-around my-3">
					<div className={device.value === 2 ? "btn btn-success" : "btn btn-secondary"} onClick={greenButtonClicked}>{"Verde"}</div>
					<div className={device.value === 1 ? "btn btn-danger" : "btn btn-secondary"} onClick={redButtonClicked}>{"Rojo"}</div>
				</div>
				<div className="d-flex justify-content-around my-3">
					<div className="text-btn" onClick={() => { this.setState({ editing: true }) }}>Editar</div>
				</div>

			</div>
		)
	}

	renderEdit() {
		const { groups, device, onClose } = this.props
		const { name, locationUrl, groupId } = this.state

		let doneClicked = () => {
			api.updateDevice(device._id, { name: name, locationUrl: locationUrl, groupId: groupId }, groupId, device.groupId)
			this.setState({ editing: false });
		}
		let deleteClicked = () => {
			if (window.confirm("Eliminar Luminaria " + name + "?")) {
				api.deleteDevice(device._id);
				onClose();
			}
		}

		return (
			<div className='detail-edit-form'>
				<div className="d-flex justify-content-between my-2">
					<div >ID:</div>
					<div >{device._id}</div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Nombre</div>
					<div ><input type="text" onChange={(e) => { this.setState({ name: e.target.value }) }} value={name} /></div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Ubicación</div>
					<div ><input type="text" onChange={(e) => { this.setState({ locationUrl: e.target.value }) }} value={locationUrl} /></div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Grupo</div>
					<div ><select onChange={(e) => { this.setState({ groupId: e.target.value }) }} value={device.groupId}>
						{groups.map((gr) => <option value={gr._id}>{gr.name}</option>)}
					</select></div>
				</div>
				<div className="detail-edit-buttons">
					<button className="btn btn-secondary" onClick={doneClicked}>OK</button>
					<button className="btn btn-danger" onClick={deleteClicked}>Eliminar</button>
				</div>
			</div>

		)
	}
}

export default LampDetails;