import React, { Component } from 'react';
import DeviceMap from '../components/DeviceMap';
import MapMenu from '../components/streetLight/MapMenu';
import LampDetails from '../components/streetLight/LampDetails';
import GroupDetails from '../components/streetLight/GroupDetails';
import SectionDetails from '../components/streetLight/SectionDetails';
import '../css/streetLight.css'

var api = require("../../utils/api-v2");
var refreshInterval;

class StreetLight extends Component {
	state = {
		devices: [
			{ _id: "id1", name: "device1", powerState: "ON", connected: true, percentage: 0, lat: -33.450578, lng: -70.646058 },
			{ _id: "id2", name: "device2", powerState: "OFF", connected: true, percentage: 0, lat: -33.470668, lng: -70.646058 },
			{ _id: "id3", name: "device3", powerState: "ON", connected: false, percentage: 0, lat: -33.480758, lng: -70.646058 },
			{ _id: "id4", name: "device4", powerState: "OFF", connected: false, percentage: 0, lat: -33.490548, lng: -70.646058 },
			{ _id: "id5", name: "device5", powerState: "ON", connected: true, percentage: 0, lat: -33.509938, lng: -70.646058 },
		],
		devicesFiltered: [],
		groupSelected: ["Chile", "Santiago", "Todos los semáforos"],
		groupSelectedId: ["", "", ""],
		deviceHoverId: "",
		groups: [
			{ _id: "id1", name: "Group1", devices: ["id1", "id2"] },
			{ _id: "id2", name: "Group2", devices: ["id3", "id4", "id5"] }],
		sections: [
			{ _id: "grId1", name: "Section1", children: ["grId5"], level: 0, groups: [] },
			{ _id: "grId2", name: "Section2", children: ["grId6"], level: 0, groups: [] },
			{ _id: "grId3", name: "Section3", children: ["grId7"], level: 0, groups: [] },
			{ _id: "grId4", name: "Section4", children: ["grId8"], level: 0, groups: [] },
			{ _id: "grId5", name: "subsection1", children: [], groups: ["id1"], level: 1 },
			{ _id: "grId6", name: "subsection2", children: [], groups: ["id2"], level: 1 },
			{ _id: "grId7", name: "subsection3", children: [], groups: [], level: 1 },
			{ _id: "grId8", name: "subsection4", children: [], groups: [], level: 1 },
		],
		routines: [],
		deviceDetailsSelected: null,
		groupDetailsSelected: null,
		sectionDetailsSelected: null,
		loading: true

	}

	componentDidMount() {
		this.mapsEvent({ name: "getInfo" });
		refreshInterval = setInterval(this.mapsEvent, 3000, { name: "getInfo" });
	}

	componentWillUnmount() {
		clearInterval(refreshInterval);
	}

	mapsEvent = event => {
		const { setCookie } = this.props

		if (event.name === "getInfo") {

			api.getSessionInfo2().then(
				function (data) {

					if (data.data.error === "Logged out") {
						clearInterval(refreshInterval);
						setCookie("");
						return;
					}

					this.setState({
						devices: data.data.devices,
						groups: data.data.groups,
						sections: data.data.sections,
						routines: data.data.routines,
						timezone: data.data.timezone,
						loading: false
					});

				}.bind(this)
			);
		}
	}

	filterDevices(devices, groups, sections, groupSelectedId) {

		let devs = []
		let getFromGroup = (groupId) => {
			let group = groups.filter((gr) => gr._id === groupId)[0]
			if (group) {
				for (let i = 0; i < group.devices.length; i++) {
					const devId = group.devices[i];
					let dev = devices.filter((d) => d._id === devId)[0];
					if (dev) devs.push(dev);
				}
			}
		}
		let getFromSection = (sectionId) => {
			let section = sections.filter((se) => se._id === sectionId)[0]
			if (section) {
				if (section.level === 0) {
					for (let i = 0; i < section.children.length; i++) {
						const subSectionId = section.children[i];
						getFromSection(subSectionId);
					}
				} else {
					for (let i = 0; i < section.groups.length; i++) {
						const groupId = section.groups[i];
						getFromGroup(groupId);
					}
				}
			}


		}

		if (groupSelectedId[2] !== "") {
			getFromGroup(groupSelectedId[2]);
			return devs;
		} else if (groupSelectedId[1] !== "") {
			getFromSection(groupSelectedId[1]);
			return devs;
		} else if (groupSelectedId[0] !== "") {
			getFromSection(groupSelectedId[0]);
			return devs;
		} else return devices;
	}

	groupSelectedChanged = (level, id) => {

		const { groupSelectedId, groupSelected, sections, groups } = this.state
		let grIdArray = [...groupSelectedId];
		let grArray = [...groupSelected];
		grIdArray[level] = id;

		if (level === 0) {
			let section = sections.filter((s) => s._id === id)[0]
			if (section) grArray[0] = section.name
			else grArray[0] = "";
			grArray[1] = ""
			grArray[2] = ""
			grIdArray[1] = "";
			grIdArray[2] = "";

		} else if (level === 1) {
			let section = sections.filter((s) => s._id === id)[0]
			if (section) grArray[1] = section.name
			else grArray[1] = "";
			grArray[2] = ""
			grIdArray[2] = "";

		} else if (level === 2) {
			let group = groups.filter((gr) => gr._id === id)[0]
			grArray[2] = group.name

		}
		if (grArray[0] === "" && grArray[1] === "" && grArray[2] === "") grArray = ["Chile", "", "Todos los semáforos"]

		this.setState({ groupSelectedId: grIdArray, groupSelected: grArray });

	}

	render() {
		const { deviceHoverId, devices, groups, sections, groupSelectedId } = this.state
		let devicesFiltered = this.filterDevices(devices, groups, sections, groupSelectedId);
		return <div className="position-relative">
			<DeviceMap
				devices={devicesFiltered}
				menuItemHoverId={deviceHoverId}
				onMarkerClicked={(id) => { this.setState({ deviceDetailsSelected: id }) }}
			/>;
			{this.renderMenu()}
			{this.renderDetailedView()}
		</div>
	}

	renderDetailedView() {
		const { deviceDetailsSelected, groupDetailsSelected, sectionDetailsSelected, devices, groups, sections, routines } = this.state
		let closeViewClicked = () => {
			this.setState({
				deviceDetailsSelected: null,
				groupDetailsSelected: null,
				sectionDetailsSelected: null
			})
		}
		return (
			(deviceDetailsSelected || groupDetailsSelected || sectionDetailsSelected) ? <div className="shade-div">
				<div className="detail-box">
					<div className="close-btn" onClick={closeViewClicked}>
						<img src="/img/icons/close.png" alt="close" width="32" height="32" />
					</div>
					{deviceDetailsSelected ? <LampDetails device={devices.filter((dev) => dev._id === deviceDetailsSelected)[0]} groups={groups} onClose={closeViewClicked} /> : null}
					{groupDetailsSelected ? <GroupDetails group={groups.filter((gr) => gr._id === groupDetailsSelected)[0]} onClose={closeViewClicked} routines={routines} onRefreshInfo={() => { this.mapsEvent({ name: "getInfo" }); }} /> : null}
					{sectionDetailsSelected ? <SectionDetails section={sections.filter((s) => s._id === sectionDetailsSelected)[0]} onClose={closeViewClicked} routines={routines} onRefreshInfo={() => { this.mapsEvent({ name: "getInfo" }); }} /> : null}
				</div>
			</div> : null
		)
	}



	renderMenu() {
		const { groupSelected, sections, groups, devices, groupSelectedId } = this.state

		return < MapMenu
			groupSelectedId={groupSelectedId}
			groupSelected={groupSelected}
			sections={sections}
			groups={groups}
			devices={devices}
			groupSelectedChanged={this.groupSelectedChanged}
			onDeviceHover={(id) => { this.setState({ deviceHoverId: id }) }}
			onDeviceClicked={(id) => { this.setState({ deviceDetailsSelected: id }) }}
			onGroupDetailsClicked={(id) => { this.setState({ groupDetailsSelected: id }) }}
			onSectionDetailsClicked={(id) => { this.setState({ sectionDetailsSelected: id }) }}
			onRefreshInfo={() => { this.mapsEvent({ name: "getInfo" }); }}
		/>

	}


}

export default StreetLight;